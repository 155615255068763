@import url(https://rsms.me/inter/inter.css);
@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,700&display=swap);
* {
  border-radius: 0;
  box-sizing: border-box;
  font-weight: normal;
  margin: 0;
  outline: none;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent; }

a {
  color: #272c35;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: 0.2s;
  transition: 0.2s; }
  a:hover {
    color: #3d4452; }
  a:active {
    color: #111418; }

body {
  background: #272c35;
  color: #000;
  margin: 0;
  font: normal 1em/1 'Inter var', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font: normal 1em/1 'IBM Plex Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  line-height: 1.6; }

form label {
  display: block;
  margin: 1em 0; }
  form label span {
    display: block;
    font-weight: 500;
    margin-bottom: 1em; }

form button,
form input,
form textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #f6f7f8;
  border: none;
  border-radius: 0.25em;
  font: inherit;
  padding: 1em;
  -webkit-transition: 0.2s;
  transition: 0.2s; }

form button {
  background: #272c35;
  border-radius: 0.25em;
  color: #fff;
  cursor: pointer;
  line-height: normal;
  padding: 1em 2em; }
  form button:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2); }
  form button:active {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8); }

form input,
form textarea {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  width: 100%; }
  form input:focus,
  form textarea:focus {
    border-color: #272c35; }

form textarea {
  line-height: 1.6;
  min-height: 10em; }

h1,
h2,
h3 {
  font-weight: 600;
  margin: 1em 0; }

h1 {
  font-size: 4em; }

h2 {
  font-size: 3em; }

h3 {
  font-size: 2em; }

hr {
  border: none;
  height: 1px;
  margin: 2em 0;
  position: relative; }

img {
  max-width: 100%;
  vertical-align: top; }

ol,
ul {
  margin: 1em 0; }
  ol li,
  ul li {
    line-height: 1.6;
    list-style: square;
    margin: 1em 0 1em 2em; }

p {
  margin: 1em 0;
  line-height: 1.6; }

#root {
  display: flex;
  flex-direction: column;
  height: 100vh; }

