// fonts

@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,700&display=swap');

// reset

* {
  border-radius: 0;
  box-sizing: border-box;
  font-weight: normal;
  margin: 0;
  outline: none;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

// variables

$color-background: #fff;
$color-background-dark: #f6f7f8;
$color-background-darker: #ecf0f1;
$color-foreground: #000;
$color-accent: #272c35;

$line-height: 1.6;

// global

a {
  color: $color-accent;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    color: lighten($color-accent, 10%);
  }

  &:active {
    color: darken($color-accent, 10%);
  }
}

body {
  background: $color-accent;
  color: $color-foreground;
  margin: 0;
  font: normal 1em/1 'Inter var', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font: normal 1em/1 'IBM Plex Mono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
  line-height: $line-height;
}

form {
  label {
    display: block;
    margin: 1em 0;

    span {
      display: block;
      font-weight: 500;
      margin-bottom: 1em;
    }
  }

  button,
  input,
  textarea {
    appearance: none;
    background: $color-background-dark;
    border: none;
    border-radius: 0.25em;
    font: inherit;
    padding: 1em;
    transition: 0.2s;
  }

  button {
    background: $color-accent;
    border-radius: 0.25em;
    color: $color-background;
    cursor: pointer;
    line-height: normal;
    padding: 1em 2em;

    &:hover {
      filter: brightness(1.2);
    }

    &:active {
      filter: brightness(0.8);
    }
  }

  input,
  textarea {
    user-select: text;
    width: 100%;

    &:focus {
      border-color: $color-accent;
    }
  }

  textarea {
    line-height: $line-height;
    min-height: 10em;
  }
}

h1,
h2,
h3 {
  font-weight: 600;
  margin: 1em 0;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: 3em;
}

h3 {
  font-size: 2em;
}

hr {
  border: none;
  height: 1px;
  margin: 2em 0;
  position: relative;
}

img {
  max-width: 100%;
  vertical-align: top;
}

ol,
ul {
  margin: 1em 0;

  li {
    line-height: $line-height;
    list-style: square;
    margin: 1em 0 1em 2em;
  }
}

p {
  margin: 1em 0;
  line-height: $line-height;
}

// layout

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
